import React, { useEffect } from 'react';
import './About.css';
import { TimeLineData } from  "../../constants/constants";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'; // Import ScrollTrigger plugin

const About = () => {
    
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger); // Register ScrollTrigger plugin

        const timelineItems = document.querySelectorAll('.timeline-item');

        timelineItems.forEach((item, index) => {
            gsap.to(item, {
                opacity: 1,
                y: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: item,
                    start: 'top 80%',
                    end: '+=100',
                    scrub: true,
                },
                delay: index * 0.2, // Stagger the animations with a delay for each item
            });
        });
    }, []);

    return (
        <div className='about section__padding' id='about'>
            <div className='app__section_divider' />
            <div className='app__title'>About</div>
            <div className='app__container'>
                <p className='app__about-p'>Dedicated and adaptable web developer with a strong foundation in frontend and backend technologies. Proven track record of delivering high-quality solutions while collaborating closely with clients and teams. Thrives in dynamic environments, leveraging excellent communication and problem-solving skills.</p>
                {/* Render the timeline data */}
                <div className="timeline">
                    {TimeLineData.map((item, index) => (
                        <div key={index} className="timeline-item">
                            <div className="timeline-year">{item.year}</div>
                            <div className="timeline-text">{item.text}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default About;
